import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import toggleFav from "../../services/toggleFav";
import { useSnackbar } from 'notistack';
// import Button from "@mui/material/Button";

function FavButton({ favorited, favoritesCount, handler, right, slug, text }) {
  const [loading, setLoading] = useState(false);
  const { headers, isAuth } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();   //closeSnackbar, 
  
  const buttonPosition = right ? "pull-xs-right" : "";
  const buttonStyle = favorited ? "active" : "";
  const buttonText = text ? "Favorite" : !isAuth ? "" : "";

  const handleClick = () => {
    if (!isAuth) {
      const action = snackbarId => (
        <>
          {/* <button
            className={`btn btn-sm btn-link`}
            disabled={loading}
            onClick={console.info(`You need to login first`)}
          >
            <i className="ion-heart-outline"></i> {"Undo"}
          </button> */}
          <button
            className={`btn btn-sm btn-link`}
            disabled={loading}
            onClick={() => { closeSnackbar(snackbarId)}}
          >
            <i className="ion-heart"></i> {"Dismiss"}
          </button>
        </>
      );
      enqueueSnackbar("You need to login first", { action, autoHideDuration: 7000 })
    }
    // if (!isAuth) return alert("You need to login first");

    setLoading(true);

    toggleFav({ slug, favorited, headers })
      .then(handler)
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  return (
    <button
      className={`btn btn-sm btn-outline-primary ${buttonPosition} ${buttonStyle}`}
      disabled={loading}
      onClick={handleClick}
    >
      <i className="ion-heart"></i> {buttonText}
      <span className="counter"> ( {favoritesCount} )</span>
    </button>
  );
}

export default FavButton;
