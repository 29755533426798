import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import getBodyMdx from "../services/getBodyMdx";
// import {getMDXComponent} from 'mdx-bundler/client'
import {Spinner} from "components/spinner"
// import {run} from '@mdx-js/mdx'
// import * as runtime from 'react/jsx-runtime'
import * as mdxBundler from 'mdx-bundler/client/index.js'
// import { getImgProps } from "images";
// import { BlogImage } from "images";

function useArticleMdx({ slug }) {
	const { headers = null } = useAuth() || {};
	const [mdx, setArticleMdx] = useState({
		code: "",
		frontmatter: {},
	});
	const [loading, setLoading] = useState(true);
	// const [Komponnet, setKomponent] = useState(null);
	// const { headers } = useAuth();

	useEffect(() => {
		// console.dir(tabName)
		if (!slug) return;

		setLoading(true);
		
		getBodyMdx({ slug, headers })
			.then((resp) => {
				console.dir(resp)

				setArticleMdx(resp)
			})
			.catch(console.error)
			.finally(() => setLoading(false));
	}, [headers, slug]);
	if(loading) {
		return (
			<div className="article-preview">
				<Spinner size={16} showSpinner={loading} />
			</div>
		)
	}
	const Kcomponent = mdxBundler.getMDXComponent(mdx.code)
	const Komponent = <Kcomponent className="prose" key={slug} />
	return { Komponent, frontmatter:mdx.frontmatter, loading };
}

// function Post({code, frontmatter}) {
// 	// it's generally a good idea to memoize this function call to
// 	// avoid re-creating the component every render.
// 	console.dir(frontmatter);
// 	console.dir(code);
// 	// const Komponent = React.useMemo(() => getMDXComponent(code), [code])
// 	const Komponent = mdxBundler.getMDXComponent(code)
// 	console.dir(Komponent);
// 	return (
// 		<>
// 			<header>
// 				<h1>{frontmatter?.title}</h1>
// 				<p>{frontmatter?.description}</p>
// 			</header>
// 			<main>
// 				<Komponent />
// 			</main>
// 		</>
// 	)
// }

export default useArticleMdx;







