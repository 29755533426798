/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
import Overview from "pages/LandingPages/Privacy/sections/Overview";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/privacy_20230916_1400x600.jpg";
// import bgImage1 from "assets/images/privacy_20230916_480x206.jpg";

function Privacy() {
	return (
		<>
			<DefaultNavbar
				routes={routes}
				// action={{
				//   type: "external",
				//   route: "https://www.creative-tim.com/product/material-kit-react",
				//   label: "free download",
				//   color: "default",
				// }}
				// transparent
				// light
				relative
				sticky
			/>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.1),
							rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					// sizes: "(max-width: 480px) 480px, 1400px" ,
					// srcSet: `${bgImage1}, ${bgImage}`,
				}}
			>
			</MKBox>
			{/* <Overview /> */}
			<Card
				sx={{
					p: 2,
					mx: { xs: 2, lg: 3 },
					mt: -8,
					mb: 4,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
				}}
			>
				<Overview />
			</Card>
			<Footer content={footerRoutes} />
		</>
	);
}

export default Privacy;
