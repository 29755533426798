import axios from "axios";
import errorHandler from "../helpers/errorHandler";

async function getArticle({ headers, slug }) {
	try {
		console.dir(slug)
		const { data } = await axios({ headers, url: `/api/blog/articles/${slug}` });
		console.dir(data)
		return data.article;
	} catch (error) {
		errorHandler(error);
	}
}

export default getArticle;
