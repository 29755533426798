/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 React
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import DefaultFooter from "layouts/sections/page-sections/page-footer";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// Images
import bgImage from "assets/images/drama-20240612.svg";

function AboutUs() {
	return (
		<>
			<DefaultNavbar
				// sx={{marginTop:0, paddingTop:0}}
				routes={routes}
				// action={{
				//   type: "external",
				//   route: "https://www.creative-tim.com/product/material-kit-react",
				//   label: "free download",
				//   color: "default",
				// }}
				// transparent
				// light
				relative
				sticky
			/>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.6),
						rgba(gradients.dark.state, 0.6)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					// marginTop:"6.5rem",
				}}
			>
				{/* <Grid
					container
					item
					xs={12}
					lg={8}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					sx={{ mx: "auto", textAlign: "center" }}
				> */}
					<MKTypography
						variant="h1"
						color="white"
						sx={({ breakpoints, typography: { size } }) => ({
							[breakpoints.down("md")]: {
								fontSize: size["3xl"],
							},
						})}
					>
						An amazing learning school
					</MKTypography>
				{/* </Grid> */}
			</MKBox>
			<Card
				sx={{
					p: 2,
					mx: { xs: 2, lg: 3 },
					mt: -8,
					mb: 4,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
				}}
			>
				<Stack direction="column">
					<Information />
					<Team />
				</Stack>
			</Card>
			<Footer content={footerRoutes} />
		</>
	);
}

export default AboutUs;
