import axios from "axios";
import errorHandler from "../helpers/errorHandler";

async function getBodyMdx({slug}) {
	try {
		const { data } = await axios({ url: `/api/blog/article/cnt/${slug}` });
		console.dir(data)
		return data;
	} catch (error) {
		errorHandler(error);
	}
}

export default getBodyMdx;
