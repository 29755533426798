/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import { useTheme } from '@mui/material/styles';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "layouts/sections/components/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "layouts/sections/components/Cards/BlogCards/CenteredBlogCard";

// const bgImage6_480 = "images/ekass/forest-20240612-480x206.jpg";

function Information() {
	// const theme = useTheme();
	// const { palette } = theme;
	// const { text } = palette;
	return (
		// <MKBox component="section" py={2}>
			// <Container>
				<Grid container spacing={0} alignItems="center">
					<Grid item xs={12}
						// sx={{
						// 	backgroundImage: `url(${bgImage6_480})`,
						// 	transform: "scale(0.94)",
						// 	filter: "blur(12px)",
						// 	backgroundSize: "cover",
						// }}
					>
						<MKTypography variant="body1" mt={1} mb={1} sx={{px:1}}>
							Ekambuli High School, a beacon of academic excellence in Western Kenya, stands tall among educational institutions. <br/>
							The school&apos;s inception in 1980, under the auspices of Mr. Hector Sutherland and divine grace, marked the birth of a robust center for learning. <br/><br/>
							In 2022, our KCSE candidates breathed life into this sleeping giant, surpassing the set target of 4.5 to achieve an impressive 4.582. This remarkable feat was the fruit of a collective effort from all stakeholders. Our students, receptive and focused, embraced instructions and ideas with open minds. Our dedicated teachers, supportive parents, and other stakeholders also played significant roles in this achievement. <br/><br/>

							As we look to 2023, we see a cohort of candidates brimming with potential. <br/>
							However, some still exhibit complacency and require constant supervision to fully tap into their abilities. <br/>
							We continue to encourage them to cultivate self-drive and strive for excellence. <br/><br/>
							At Ekambuli High School, our mission extends beyond academics. <br/>
							We aim to nurture confident, well-adjusted, and socially responsible young citizens. <br/><br/>
							We instill in them respect for the richly diverse environment and community they are privileged to be a part of and encourage them to seize every learning opportunity this presents. <br/>
							Our commitment to this mission is unwavering, as we continue to shape the leaders of tomorrow. <br/><br/>

							Established in 1965, Ekambuli Secondary School is a public institution that offers both day and boarding options for students. <br/>
							It is situated in the serene rural setting of Khwisero Sub-county, Kakamega County, Kenya. <br/>
							The school was founded under the auspices of the Church of God E.A.K and initially served as an extension of Ekambuli Primary School, operating as a mixed day Harambee school.<br/>

							Over the years, Ekambuli Secondary School has evolved into a double-stream institution, boasting a student population of approximately 488. The school is well-equipped with modern facilities and is committed to providing a comprehensive education that not only nurtures the academic abilities of students but also their talents. It places a strong emphasis on instilling values in its students. 

							The school has a proud history of guiding numerous students towards higher education in universities, while many others have pursued further studies in colleges. <br/>
							It takes pride in producing self-reliant individuals of integrity. The school&apos;s leadership comprises Mr. Sunguti Isaac, the Principal, and Mr. Opingo Samuel, the Deputy Principal. They lead a team of professional staff who are dedicated to helping students realize their full potential. <br/>
							Ekambuli Secondary School is located in a beautiful and serene rural environment along Sabatia - Luanda road and Khumsalaba - Yala road; about 30 minutes&apos; drive from Luanda town. 
						</MKTypography>
					</Grid>
					<Grid item xs={12} px={1}>
						<Grid container justifyContent="flex-start">
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 1 }}>
									<DefaultInfoCard
										icon="hotel"
										title="Boarding"
										description="Our boarding facilities, which include a kitchen and dining hall, offer the most affordable rates. The department is led by Mr. Wasike Joseph and Ms. Sikuku."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 1 }}>
									<DefaultInfoCard
										icon="celebration"
										title="Clubs and Societies"
										description="Under the leadership of Mr. Wafula Timothy, we offer a variety of clubs including the Journalism Club, Scouts Club, Science Congress Club, Music Club, Drama Club, Young Farmers Club, Straight Talk Club, and Red Cross Club."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 1 }}>
									<DefaultInfoCard
										icon="hiking"
										title="Scounting"
										description="Our Scouting club, one of the most active in the school, encourages students to develop self-knowledge and curiosity. The club, led by patrol leader Hellen Andeso, emphasizes the importance of common sense."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 1 }}>
									<DefaultInfoCard
										icon="language"
										title="Language Department"
										description="Headed by Miss Etsobi Truphosa, our language department aims to produce well-rounded students who can speak both Kiswahili and English accurately, fluently, confidently, and appropriately in various contexts."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 1 }}>
									<DefaultInfoCard
										icon="groups"
										title="Humanities"
										description="The Humanities department, led by Mr. Wasike Joseph, covers Geography, History, and Christian Religious Education. These social sciences subjects teach students about interpersonal relationships and environmental interactions."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 1 }}>
									<DefaultInfoCard
										icon="support"
										title="Guidance/Counselling"
										description="Our Guidance and Counselling department, headed by Mr. Opingo B. Samuel & Ms. Etsobi Truphosa, provides a supportive environment for students to grow mentally, physically, socially, and academically into morally upright individuals."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 2 }}>
									<DefaultInfoCard
										icon="science"
										title="Science"
										description="The Science department, led by Mr. Madara, covers Biology and Agriculture."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 2 }}>
									<DefaultInfoCard
										icon="fax"
										title="Technical"
										description="The Technical department, headed by Mr. Eshikati, covers Geography and Business Studies."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 2 }}>
									<DefaultInfoCard
										icon="cast_for_education"
										title="Teachers"
										description="We have a dedicated team of 20 TSC teachers and 5 teachers employed by BOM."
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 2 }}>
									<DefaultInfoCard
										icon="support_agent"
										title="Support staff"
										description="We have 20 well-trained and dedicated support staff members."
									/>
								</MKBox>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			// </Container>
	);
}

export default Information;
