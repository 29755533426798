// import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import NavItem from "../NavItem";
// import SourceCodeLink from "../SourceCodeLink";
import DropdownMenu from "./DropdownMenu";

function Navbar() {
  const { isAuth } = useAuth();
  // console.dir(isAuth)
  
  return (
    <nav className="navbar navbar-light" style={{padding:0}}>
      <div className="container1">
        {/* <Link className="navbar-brand" to="/">
          Harmann
        </Link> */}

        {/* <SourceCodeLink left /> */}

        {/* <ul className="nav navbar-nav pull-xs-right"> */}
          {/* <NavItem text="Home" icon="ion-compose" url="/" /> */}

          {isAuth && (
              // <>
                // <NavItem text="New Article" icon="ion-compose" url="/editor" />
                <DropdownMenu />
              // </>
          )}

          {!isAuth && (
            <>
              <NavItem text="" icon="ion-log-in" url="/sign-in" />
              {/* <NavItem text="Sign up" url="/register" /> */}
            </>
          )}
        {/* </ul> */}
      </div>
    </nav>
  );
}
export default Navbar;
