import axios from "axios";
import errorHandler from "../helpers/errorHandler";

// prettier-ignore
async function getArticles({ headers, limit = 5, location, page = 0, tagName, username }) {
  try {
    const url = {
      favorites: `/api/blog/articles?favorited=${username}&&limit=${limit}&&offset=${page}`,
      feed: `/api/blog/articles/feed?limit=${limit}&&offset=${page}`,
      global: `/api/blog/articles?limit=${limit}&&offset=${page}`,
      profile: `/api/blog/articles?author=${username}&&limit=${limit}&&offset=${page}`,
      tag: `/api/blog/articles?tag=${tagName}&&limit=${limit}&&offset=${page}`,
    };
    console.dir(url[location])
    const { data } = await axios({ url: url[location], headers });  //, baseURL:"https://ekambulihighschool.sc.ke"
    console.dir(data)
    
    
    return data;
  } catch (error) {
    errorHandler(error);
  }
}

export default getArticles;
