import { Outlet } from "react-router-dom";
import React from "react";
// import {Link} from "react-router-dom";
// import Navbar from "./components/Navbar";
// import AuthProvider from "./context/AuthContext";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
import routes from "routes";
// import Footer from "./components/Footer";
// import { ButtonLink } from 'components/button'
// import { RssIcon } from 'components/icons'
import footerRoutes from "footer.routes";
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";
// import {HeroSection} from "layouts/sections/hero-section";
// import bgImage from "assets/images/kody_flipping_flying_blue.jpg";
// import {Spacer} from "components/spacer";
// import { images } from 'images'

// import 'assets/css/article.css';

// const bimage = {
// 	id: 'assets/images/drama-20240612-1400x600.jpg',
// 	alt: `drama...`,
// 	style: { aspectRatio: '2.1281198' },
// }
function BlogApp() {
	return (
		<main className="px-8 xs:mx-2vw lg:mx-10vw font-sans Noto-Sans -apple-system BlinkMacSystemFont Segoe-UI Roboto Helvetica-Neue Arial">
			<>
				<DefaultNavbar
					routes={routes}
					relative
					sticky
				/>
				<div className="shadow-inner px-8 pb-4 mb-0">
					<div className="mx-auto px-4">
						<h1 className="text-center pb-1 font-bold text-5xl">EKASS</h1>
						<p className="text-center mb-0 text-white">A place to share your knowledge.</p>
					</div>
				</div>
				{/* <HeroSection
					// title="Learn more about our School from great articles."
					// subtitle={null}
					// 	<>
					// 		<span>{`Enjoy reading our latest writing here.`}</span>
					// 	</>
					// }
					imageBuilder={images.schooldrama}
					// image={<img src={bgImage} alt="" className="w-[100%]" />}
					imageSize="large"
				/> */}
				<Outlet />
			</>
			<Footer content={footerRoutes} />
		</main>
	);
}

export default BlogApp;
