// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo.png";	//logo-ct-dark.png

// import Home from "pages/Home";
// import AboutUs from "pages/LandingPages/AboutUs";
// import AboutUs from "layouts/pages/landing-pages/about-us";

const date = new Date().getFullYear();

export default {
	brand: {
		name: "Ekambuli High School",
		image: logoCT,
		route: "/",
	},
	socials: [
		{
			icon: <FacebookIcon />,
			link: "fb35",
		},
		{
			icon: <TwitterIcon />,
			link: "x24345",
		},
		{
			icon: <YouTubeIcon />,
			link: "yt4535",
		},
	],
	menus: [
		{
			name: "useful links",
			items: [
				// { name: "Home", href: "https://iradesign.io/" },
				// { name: "About Us", href: "https://www.creative-tim.com/bits" },
				{ name: "home", route: "/" },
				{ name: "about us", route: "/about-us" },
				{ name: "photo gallery", route: "/privacy-policy" },
			],
		},
		// {
		// 	name: "legal",
		// 	items: [
		// 		{ name: "terms of use policy", route: "/terms-of-use" },
		// 		{ name: "privacy policy", route: "/privacy-policy" },
		// 		{ name: "licenses (EULA)", href: "l5345" },
		// 	],
		// },
	],
	copyright: (
		<MKTypography variant="button" fontWeight="regular">
			<MKTypography
				component="a"
				href="/terms-of-use"
				// target="_blank"
				rel="noreferrer"
				variant="button"
				fontWeight="regular"
			>
				Terms of use
			</MKTypography>
			<MKTypography
				component="a"
				href="/privacy-policy"
				// target="_blank"
				rel="noreferrer"
				variant="button"
				fontWeight="regular"
			>
				&nbsp;- Privacy Policy 
			</MKTypography>
			<MKTypography
				component="a"
				href="/contact-us"
				// target="_blank"
				rel="noreferrer"
				variant="button"
				fontWeight="regular"
			>
				&nbsp;- Contact 
			</MKTypography>
			&nbsp;- All rights reserved. Copyright &copy; {date} by{" "}
			<MKTypography
				component="a"
				href="cddf"
				target="_blank"
				rel="noreferrer"
				variant="button"
				fontWeight="regular"
			>
				States Grid
			</MKTypography>
			.
		</MKTypography>
	),
};
