/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
// import Learning from "pages/LandingPages/WhyUs";
import OverviewPg from "pages/LandingPages/WhyUs/Pages/Overview";
import ApproachPg from "pages/LandingPages/WhyUs/Pages/Approach";

import AchievementsPg from "pages/LandingPages/WhyUs/Pages/Achievements";
// function OverviewPage() {
//     return <Overview />;
// }

// export default function LearningPage() {
//     return <Learning />;
// }

export function Overview() {
    return <OverviewPg />;
}

export function Approach() {
    return <ApproachPg />;
}

export function Achievements() {
    return <AchievementsPg />;
}

// export * as Overview from "pages/LandingPages/Learning/Sections/Overview";

